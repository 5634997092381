* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.logo {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

header {
  background-color: transparent;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
}

.nav-container {
  display: flex;
  align-items: center;
}

nav a {
  text-decoration: none;
  margin: 0 2rem;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
}

nav a:hover {
  color: black;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (min-width: 1024px) {
  
  header {
    position: absolute;
  }

  .logo {
    background-image: url("../assets/logga_desktop.png");
    height: 90px;
    width: 260px;
  }
}


@media only screen and (max-width: 1024px) {
  
  header {
    position: fixed;
    background-color: white;
    border-bottom: 1px solid lightgrey;
  }

  .logo {
    background-image: url("../assets/logga_mobil.png");
    height: 70px;
    width: 240px;
    margin-left: -25px;
  }

  nav a {
    color: black;
  }
  
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: white;
    transition: 0.5s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 20px;
  }

}